.form-row {
    display: flex;
    margin: 0;
    > div {
        padding-right: 30px;
    }
}

.form-group, .form-row {
    max-width: 75%;
}

.address .form-group {
    width: 50%;
}

#div_id_address_2, #div_id_last {
    padding-right: 0;
}