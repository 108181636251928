.fund_amounts > div.d-flex {
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1em;
    @include media-breakpoint-up(md) {
        flex-wrap: wrap;
    }
    label {
        margin: 0;
        padding: 0;
    }
    input.amount {
        width: 8em;
    }
}
.input-symbol-dollar {
    position: relative;
    > * {
        padding-left: 1.5em;
    }
    &::before {
        content: "$";
        position: absolute;
        left: .4em;
        top: .5em;
        color: $gray-600;
    }
}

.store {
    align-items: flex-start !important;
    label {
        padding-left: 20px;
        flex-grow: 1;
        height: 100%;
    }
}

.store-row {
    flex-direction: column;
    width: 25%;
    padding: 0 25px;
    > div {
        align-self: center;
        input.amount {
            width: 100%;
            text-align: center;
        }
    }
    .fund-title {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
    }
    img {
        padding-bottom: 20px;
        width: 100%;
        height: 200px;
        object-fit: cover;
    }
}



@include media-breakpoint-up(md) {
    .underline {
        flex-grow: 1;
        border-bottom: #000 dashed 1px;
        margin: 0 1em;
    }
    .fund_amounts > div.d-flex {
        flex-wrap: nowrap;
    }
}

@include media-breakpoint-down(md) {
    .store-row {
        width: auto;
    }
}