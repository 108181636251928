.curriculum-nav {
    a.nav-item {
        background: $gray-300;
        color: $dark-blue;
        &:hover {
            background: $gray-400;
        }
        &.active {
            background: $dark-blue;
            color: $white;
        }
    }
}
.curriculum-secondary {
    margin-bottom: 2em;
}
.linerow {
    border-bottom: 1px solid $gray-600;
    margin-bottom: 1.5em;
    padding-bottom: 1em;
}
.subject {
    button {
        text-align: left;
        color: $black;
        &:focus, .btn.focus {
            box-shadow: none;
            text-decoration: none;
        }
        &:before {
            content: "\f146"; //minus
            padding: 4px;
            margin: 0 4px 0 0;
            float:left;
            font-family: "Font Awesome 5 Free";
            -webkit-font-smoothing: antialiased;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            line-height: 1;
            font-weight: 900;
            color: $primary;
            font-size: 1.5em;
        }
        &.collapsed:before {
            content: "\f0fe"; //plus
            color: $black;
        }
    }
}