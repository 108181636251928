#annual-report-body {
    .img-fluid {
        width: 100%;
        height: auto;
    }
    .block-heading {
        .heading-text {
            padding-bottom: 20px;
        }
    }
}

.annual-display-block {
    margin-bottom: 2px;
    @include media-breakpoint-up(sm) {
        min-height: 500px;
        &.orientation-left-image .image-block {
            order: -1;
        }
    }
    @media screen and (max-width: 900px){
        flex-direction: column-reverse;
    }
    &.bg-blue {
        background: $primary;
        h2, p {
            color: #fff;
        }
        .btn-primary {
            background: #fff;
            color: $primary;
            &:hover {
                background: #ccc;
                color: $primary;
            }
        }
    }
    a {
        cursor: pointer;
    }
    .text-block {
        padding: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .text-block-inner {
        max-width: 700px;
        padding: 2em 6em;
    }
    .text-block-inner-white {
        max-width: 700px;
        padding: 2em 6em;
        h2, h1, h3, h4, h5, h6, p, div {
            color: white;
        }
    }
    .image-block {
        background-repeat: no-repeat;
        background-position: 50% 25%;
        background-size: cover;
    }
    .display-block-left {
        width: 50%;
        @media screen and (max-width: 900px){
            width: 100%;
        }
    }
    .display-block-right {
        width: 50%;
        @media screen and (max-width: 900px){
            width: 100%;
            height: 30vh;
        }
    }
}

// Home 
.btn-dark-blue {
    background: #00205B;
    color: $primary;
    &:hover {
        background: #00205B;
        color: $primary;
    }
}

.btn-medium-blue {
    background: #003E7E;
    color: $primary;
    &:hover {
        background: #003E7E;
        color: $primary;
    }
}

.btn-light-gray {
    background: #DEDEDE;
    color: $primary;
    &:hover {
        background: #DEDEDE;
        color: $primary;
    }
}

.btn-dark-gray {
    background: #53565A;
    color: $primary;
    &:hover {
        background: #53565A;
        color: $primary;
    }
}

.btn-yellow {
    background: #F4AF0E;
    color: $primary;
}

.btn-black {
    background: #000000;
    color: $primary;
    &:hover {
        background: #000000;
        color: $primary;
    }
}


