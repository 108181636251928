// Padding on Modal 

div#team-body.modal-body {
    padding: 2rem;
}

// Padding on display block

// .col.col-md-6.text-block {
//     max-width: 700px;
//     padding: 2em 6em;
// }

// .img-fluid {
//     max-width: 20vh;
//     height: auto;
// }

// .img-fluid logo {
//     max-width: 20vh;
//     height: auto;
//     justify-content: center;
// }

// Add blue background around billboard video
body.vp-center {
    background-color: #003E7E !important;
}

.billboard {
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
    position: relative;
}

div.no-video {
    height: auto;
    .overlay {
        position: relative;
    }
    img {
        width: 100%;
        height: auto;
    }
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .billboard-caption-box {
        width: 100%;
        background-color: $dark-blue;
        .container {
            p {
                color: white;
                font-size: 1.5em;
                font-weight: 300;
                margin: 0;
                padding: 20px 0 20px 0;
            }
        }
    }
}

.slide-content{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .w-100 {
        align-self: center;
        width: 50% !important;
    }
}

.slide-info{
    padding-top: 20px;
    color:white;
    word-wrap: break-word;
    width: 50%;
    .button{
        .w-100{
            font-size: 32px;
            color: #003E7E;
            margin-left: 50%;
            height: 60px;
            border-radius: 10px;
        }
    }
    h1{
        padding-top: 10px;
        color: white;
        align-items: center;
    }
    p{
        color: white;
        align-items: center;
    }
    
}

.slideshow {
	width: 100%;
}

.mySlides {
	display: none;
    color: white;
    justify-content: center;
    align-items: center;
    background-color: #003E7E;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    iframe {
        width: 100%;
        height: 550px !important;
    }
}

.mySlides .main-banner-image {
	width: 100%;
	height: 550px;
	object-fit: cover;
}

.slide-prev {
    font-size: 32px;
    cursor: pointer !important;
    position: absolute;
    top: 35%;
    left: 20%;
    @media screen and (max-width: 900px){
        left: 15%;
    }
}

.slide-next {
    font-size: 32px;
    cursor: pointer !important;
    position: absolute;
    top: 35%;
    right: 20%;
    @media screen and (max-width: 900px){
        right: 15%;
    }
}

.fades {
	-webkit-animation-name: fades;
	-webkit-animation-duration: 1.5s;
	animation-name: fades;
	animation-duration: 1.5s;
}

@-webkit-keyframes fades {
	from {opacity: .4}
	to {opacity: 1}
}

@keyframes fades {
	from {opacity: .4}
	to {opacity: 1}
}

div.has-video {
    height: auto;
    padding-bottom: 41.905%;
    background-color: #003e7e!important;
}

.team-boxes {
    padding-top: 50px;
    word-wrap: break-word;
    & > div {
        color: black;
        padding-bottom: 0.75rem;
    }
    img{
        padding-bottom: 15px;
    }
    .col-6 {
        display: flex;
        flex-direction: column;
        padding-bottom: 25px;
        p{
            font-family: "Lato", sans-serif;
            color: #53565A;
            font-size: 1.1rem;
            margin-bottom: 1.5rem;
            padding: 0;
            margin: 0;
        }
        h2{
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}

//Image stream

.block-image {
    text-align: center;
}

.anchor-nav {
    .anchor-link {
        border-bottom: 4px solid $primary;
        margin-right: 1em;
        &:hover {
            background: lighten($primary, 70%);
        }
    }
}

.block-testimonial {
    position: relative;
    background-color: $gray-200;
    background: $gray-200;
    margin: 1.5em 10px;
    padding: 0.5em 20px;
    quotes: "\201C""\201D""\2018""\2019";
}
.block-testimonial:before  {
    top: 0;
    left: 0;
    content: " ";
    display: block;
    width: 40px;
    height: 40px;
    background: url('../../static/assets/quote-left-solid.svg');  
    background-size: cover;
    position: absolute;
    opacity: 0.1;
}
.block-testimonial:after  {
    bottom: 0;
    right: 0;
    content: " ";
    display: block;
    width: 40px;
    height: 40px;
    background: url('../../static/assets/quote-right-solid.svg');  
    background-size: cover;
    position: absolute;
    opacity: 0.1;
}

.block-testimonial p {
    display: inline;
    margin-bottom: 0;
}

.donate-match {
    padding-top: 20px;
}

.testimonial-section blockquote {
    display: flex;
    flex-direction: column;
    align-items: end;
    padding: 30px 10px 0 10px;
}

.block-business_card {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 40px 20px;
    border: 1px solid $primary;
    p {
        color: $primary;
        margin: 0;
    }
    a {
        color: $primary;
        text-decoration: underline;
        text-decoration-style: dotted;
        word-wrap: break-word;   
    }
    a:hover {
        color: $gray-800;
        text-decoration: none;
        text-decoration-style: none;
    }
    .logo { 
        max-width: 60px;
        align-self: center;
    }
    .business-card-info {
        padding: 20px 0;
    }
}

// Heading
.block-heading {
    padding: 2em 0 1em 0;
}

.block-button {
    display: grid;
    .btn-center {
        justify-self: center;
    }
    .btn-right {
        justify-self: flex-end;
    }
    .btn-default-width {
        width: 25%;
    }
} 

// Accordion
.block-accordion {
    .nav-tabs {
        margin-bottom: 1em;
    }
    .panel-heading {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }
    a.nav-link {
        background: $gray-300;
        color: $dark-blue;
        border-radius: 0;
        &:hover {
            background: $gray-400;
        }
        &.active {
            background: $dark-blue;
            color: $white;
        }

    }
}
.accordion-toggle {
    &:before {
        content: "\f146"; //minus
        padding: 4px;
        margin: 0 4px 0 0;
        float:left;
        font-family: "Font Awesome 5 Free";
        -webkit-font-smoothing: antialiased;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        font-weight: 900;
        color: lighten($primary, 60%);
        font-size: 2em;
    }
    &.collapsed:before {
        content: "\f0fe"; //plus
        color: $primary;
    }
}

// Sidebar
.sidebar {
    > * {
        margin-bottom: 2em;
    }
    .block-table {
        max-width: 100%;
        overflow-x: auto;
    }
    .block-embed iframe{
        width: 100%;
        height: auto;
    }
    .block-button {
        margin: 2em 0;
        text-align: center;
    }
    .block-accordion,
    .block-quick_links,
    .block-heading {
        @extend .p-4;
        background: $primary;
        color: $white;
        & * {
            color: $white;
        }
    }
    .accordion-toggle.collapsed:before {
        color: $white;
    }
   
    .block-paragraph {
        section {
            padding: 1.5rem;
        }
        a {
            -webkit-text-decoration: dotted underline;
            text-decoration: dotted underline;
        }
        a:hover {
            text-decoration: none;
        }
        .p-dark-blue p, .p-dark-blue a {
            color: $dark-blue;
        }
        .p-light-grey p, .p-light-grey a  {
            color: $gray-300;
        }
        .p-medium-blue p, .p-medium-blue a  {
            color: $primary
        }
        .p-white p, .p-white a  {
            color: white;
        }
        .bg-dark-blue {
            background-color: $dark-blue;
        }
        .bg-light-grey {
            background-color: $gray-300;
        }
        .bg-medium-blue {
            background-color: $primary
        }
        .bg-white {
            background-color: white;
        }
    }
}
