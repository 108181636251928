@import "variables.scss";
@import "node_modules/bootstrap/scss/bootstrap";
@import "home.scss";
@import "curriculum.scss";
@import "standard_page.scss";
@import "donate.scss";
@import "annual_report.scss";
@import "forms.scss";

/*************
* Typography *
*************/

html {
    font-family: "Lato", sans-serif;
}

body {
    margin: 0;
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans",
        sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

p, .col li {
    font-family: "Lato", sans-serif;
    color: $gray-800;
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
}

h1,
.h1 {
    color: $dark-blue;
    font-size: 2.25em;
    font-weight: 400;
    margin-bottom: 0.5rem;
}

h2,
.h2 {
    color: $primary;
    font-size: 1.5em;
    font-weight: 400;
    padding: 0.5rem 0;
}

h3,
.h3 {
    color: white;
    font-size: 1.2em;
    font-weight: 300;
}

h4,
.h4 {
    color: $gray-800;
    font-size: 1.3125em;
    font-weight: 300;
}

h5,
.h5 {
    color: $dark-blue;
    font-size: 1.2em;
    font-weight: 300;
}

h6 {
    font-style: italic;
    font-size: 1.25em;
    font-weight: 300;
    color: $primary;
}

.text-white {
    color: white;
}

/******************
* Global Elements *
******************/

table {
    @extend .table-striped;
}

th,
td {
    padding: 1em;
}

th {
    color: white;
    background-color: $primary;
}

ul {
    padding-inline-start: 20px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: $gray-200;
}

.breadcrumb {
    .container {
        display: flex;
    }
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: $primary;
    font-size: 1.5em;
    font-weight: 400;
    text-align: left;
    caption-side: top;
}

.container li {
    margin-bottom: 0.5em;
}

img.richtext-image {
    float: none;
}

img.richtext-image.left {
    float: left;
    padding-right: 15px;
    width: 33%;
    height: 33%;
}

img.richtext-image.right {
    float: right;
    padding-left: 15px;
    width: 33%;
    height: 33%;
}

.nav-link {
    color: white;
    font-size: 1.2em;
    font-weight: 300;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    background: none;
}

.nav-item:hover,
.nav-item:active,
.nav-item:focus {
    color: white;
    background: $dark-blue ;
    height: 100%;
}

.nav-tabs {
    a.nav-item .nav-link {
        background: $gray-300;
        &:hover {
            background: $gray-400;
        }
        &.active {
            background: $dark-blue;
            color: $white;
        }
    }
}

.anchor-nav {
    background: none;
    padding-bottom: 2rem;
}

.anchor-link {
    display: block;
    padding: 0.5rem 1rem;
}

.anchor-link:hover,
.anchor-link:focus {
    text-decoration: none;
}

.anchor-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}

.billboard {
    height: 500px;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.btn-white {
    color: #003E7E;
    background-color: white;
}

//Carousel
.block-Carousel {
    width: 100%;
    margin-top: 30px;
    background-color: #f3f2f3;
    padding: 40px 0px;
    margin-bottom: 50px;
}
.carousel-title {
    color: #4a6a81;
    text-align: center;
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 15px;
}

ol.flickity-page-dots {
    display: none;
}

.carousel-cell {
    background-color: #f3f2f3;
    height: 400px;
    width: 25%;
    padding: 20px;
    @media (max-width: 960px){
        width: 80%;
    }
}

.carousel-card {
    border: 1px solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: white;
    .header {
        font-size: 4.5rem;
        color: #003E7E;
    }
    h2 {
        color: #385d77;
        font-size: 1.56rem;
        font-weight: 700;
        text-align: center;
    }
    p {
        padding-left: 30px;
        padding-right: 30px;
        color: #999999;
        font-size: 1rem;
        text-align: center;
        @media (max-width: 1300px){
            font-size: .85rem;
        }
    }
}

//End Carousel

//Events slider

.block-Events {
    width: 100%;
    margin-top: 30px;
    background-color: #f3f2f3;
    padding-bottom: 20px;
    margin-bottom: 50px;
    .card-top-row {
        display: flex;
        flex-direction: column;
        height: 40%;
        width: 100%;
        text-align: center;
    }

    .card-bottom-row {
        display: flex;
        flex-direction: column;
        height: 40%;
        width: 100%;
        p {
            overflow-wrap: break-word
        }
    }
    .header-day {
        color: #f4af0e;
        font-size: 75px;
        font-weight: 1000;
    }
    .header-month {
        color: #f4af0e;
        font-size: 20px;
        font-weight: 1000;
    }
    .events-button {
        color: white;
        background-color: #949495;
        padding: 4px 10px;
        font-weight: 1000;
        text-decoration: none;
        border-radius: 5px;
    }
    .button-filler {
        height: 32px;
    }
    .carousel-card {
        display: flex;
        border: 1px solid;
        flex-direction: column;
        h2 {
            text-align: center;
        }
        p {
            text-align: center;
            overflow-wrap: break-word
        }
    }
}


//Gallery

.block-Gallery {
    padding: 40px 0px;
}

.gallery {
    width: 100%;
    display: flex;
    justify-content: row;
    flex-wrap: wrap;
    justify-content: center;
}

.gallery-item {
    position: relative;
    background-color: white;
    width: 30%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    @media (max-width: 760px){
        width: 100%;
    }
}

.gallery-caption {
    text-align: center;
    position: absolute;
    bottom: 0;
    background-color: #003E7E;
    opacity: 95%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    p {
        overflow: hidden;
        word-wrap: break-word;
        color: white;
        margin: 5px;
        margin-bottom: 5px;
    }
}

.gallery-button {
    font-size: 1.32rem;
}

.gallery-image {
    width: 100%;
    height: auto;
    max-height: 250px;
}

.hidden-content {
    padding: 30px;
    background-color: #f4af0e;
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    opacity: 100%;
    overflow: hidden;
    font-size: 14px;
    line-height: 1;
    p {
        text-align: center;
        line-height: 1.2;
    }
}
.background-medium-blue {
    background-color: #003E7E;
    h2 {
        color: white;
    }
    p {
        color: white;
    }
    a {
        color: white;
    }
}
.background-dark-blue {
    background-color: #00205B;
    h2 {
        color: white;
    }
    p {
        color: white;
    }
    a {
        color: white;
    }
}
.background-light-gray {
    background-color: #DEDEDE;
    h2 {
        color: #003E7E;
    }
    p {
        color: #003E7E;
    }
    a {
        color: #003E7E;
    }
}
.background-dark-gray {
    background-color: #53565A;
    h2 {
        color: white;
    }
    p {
        color: white;
    }
    a {
        color: white;
    }
}
.background-white {
    background-color: white;
    h2 {
        color: #003E7E;
    }
    p {
        color: #003E7E;
    }
    a {
        color: #003E7E;
    }
}
.background-yellow {
    background-color: #f4af0e;
    h2 {
        color: #003E7E;
    }
    p {
        color: #003E7E;
    }
    a {
        color: #003E7E;
    }
}
#background-medium-blue {
    background-color: #003E7E;
    h2 {
        color: white;
    }
    p {
        color: white;
    }
    a {
        color: #00205B;
        background-color: white;
        padding: 5px 10px;
        border-radius: 5px;
    }
}
#background-dark-blue {
    background-color: #00205B;
    h2 {
        color: white;
    }
    p {
        color: white;
    }
    a {
        color: #00205B;
        background-color: white;
        padding: 5px 10px;
        border-radius: 5px;
    }
}
#background-light-gray {
    background-color: #DEDEDE;
    h2 {
        color: #003E7E;
    }
    p {
        color: #003E7E;
    }
    a {
        color: white;
        background-color: #003E7E;
        padding: 5px 10px;
        border-radius: 5px;
    }
}
#background-dark-gray {
    background-color: #53565A;
    h2 {
        color: white;
    }
    p {
        color: white;
    }
    a {
        color: #00205B;
        background-color: white;
        padding: 5px 10px;
        border-radius: 5px;
    }
}
#background-white {
    background-color: white;
    h2 {
        color: #003E7E;
    }
    p {
        color: #003E7E;
    }
    a {
        color: white;
        background-color: #003E7E;
        padding: 5px 10px;
        border-radius: 5px;
    }
}
#background-yellow {
    background-color: #f4af0e;
    h2 {
        color: #003E7E;
    }
    p {
        color: #003E7E;
    }
    a {
        color: white;
        background-color: #003E7E;
        padding: 5px 10px;
        border-radius: 5px;
    }
}

.gallery-item:hover {
    .hidden-content{
        display: flex;
    }
    .gallery-caption {
        display: none;
    }
}

.modal-dialog {
    margin-top: 5%;
    img {
        height: 400px;
        width: 100%;
    }
}

//End Gallery

.page-title {
    padding-top: 2rem;
}

.right-sidebar {
    padding-top: 2rem;
}

.left-sidebar {
    padding-top: 2rem;
}

.anchor-nav {
    .nav-item {
        background-color: white;
    }
}

.sidebar {
    .nav-item {
        text-decoration: underline;
        text-decoration-style: dotted;
    }
    .nav-item:hover, .nav-item a:hover {
        background: $primary;
        text-decoration: none;
        text-decoration-style: none;
    }
}

.block-paragraph {
    li {
        font-size: 1.1rem;
        padding: 5px;
        color: $gray-800;
    }
}

.alert-box {
    background-color: $yellow;
    a:hover {
        text-decoration: none;
        text-decoration-style: none;
    }
    p {
        padding:15px 0px;
        color: $dark-blue;
        font-size: 1.2em;
    }
}

.sidebar {
    .nav-item a {
        -webkit-text-decoration: dotted underline;
        text-decoration: dotted underline;
    }
    .nav-item a:hover {
        -webkit-text-decoration: none;
        text-decoration: none;
    }
}

.social-links a {
    border: none;
    margin-right: 0.25em;
    font-size: 1.2em;
    i {
        color: #fff;
    }
}

.accordion-toggle:before {
    font-size: 1.125em;
}

.rich-text img {
    max-width: 100%;
    height: auto;
}

.responsive-object {
    position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*********
* Header *
*********/

.translate-header:hover {
    text-decoration: none;
}

.navbar-dark .navbar-nav {
    flex-basis: 100%;
    flex-grow: 1;
    .nav-link {
        color: #fff;
    }
    & > .nav-item > .nav-link {
        text-transform: uppercase;
        white-space: nowrap;
    }
}

.navbar-collapse {
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.dropdown-submenu {
    position: relative;
}

.dropdown-submenu a::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 6px;
    top: 0.8em;
}

.dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: 0.1rem;
    margin-right: 0.1rem;
}

.container li {
    margin: 0;
}

.social-links {
    margin-bottom: 0;
    a {
        border: none;
        margin-right: 0.65em;
        font-size: 1.2em;
        i {
            color: #fff;
        }
    }
}

@include media-breakpoint-down(sm) {
    .navbar-collapse {
        padding-top: 1rem;
    }
    .social-links {
        font-size: 1.6em;
    }
}

#search-form {
    z-index: 2;
}

@include media-breakpoint-up(lg) {
    header {
        .nav-link {
            padding-top: 7px;
            padding-bottom: 10px;
            }

        .navbar {
            padding: 0;
        }

        .navbar-brand {
            padding: 0rem;
            margin-left: -50px;
            img{
                height: 50px;
            }
            @media screen and (max-width: 1350px){
                margin-left: 0;
            }
        }

        .navbar-collapse {
            padding: 0 0.5rem 0 1rem;
            align-items: stretch;
        }

        .navbar-toggler {
            margin: 1rem;
        }
        .upper-nav {
            display: flex;
            .nav-link {
                padding-top: 10px;
                padding-bottom: 7px;
            }
            .nav-item > a {
                color: $gray-500 !important;
                font-size: 1em;
            }
        }
        .navbar-nav {
            justify-content: flex-end;
            align-items: center;
            flex-basis: 0 !important;
            flex-grow: 0 !important;
        }
    }
}


#search-form {
    display: block;
    @include media-breakpoint-up(lg) {
        position: absolute;
        display: none;
        &.show {
            display: block;
        }
        bottom: -2.5em;
        right: 0px;
        padding: 0.5em;
        background: rgb(0, 62, 126);
    }
    &.collapsing {
        -webkit-transition: none;
        transition: none;
        display: none;
    }
}

@media (min-width: 1300px){
    .navbar-expand-custom {
        flex-direction: row;
        justify-content: flex-start;
        > .container {
            flex-wrap: nowrap;
        }
        .navbar-nav .dropdown-menu {
            position: absolute;
        }
    }
    .navbar-expand-custom .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-custom .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-custom .navbar-collapse {
        display: flex !important;
    }
    .navbar-expand-custom .navbar-toggler {
        display: none;
    }
}

/*********
* Footer *
*********/

footer {
    .navbar {
        padding-left: 0;
        .nav-item a {
            -webkit-text-decoration: dotted underline;
            text-decoration: dotted underline;
        }
        .nav-item a:hover {
            text-decoration: none;
        }
        .nav-item:hover {
            background: $primary;
        }
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding: 0;
        margin: 0 1.5em;
        &:first-child {
            margin-left: 0;
        }
    }
    a {
        color: #fff;
        text-decoration: none;
    }
    .py-1 a:hover {
        background: $primary;
        color: white;
        text-decoration: none;
    }
    #translate-column {
        margin-right: 10%;
        width: 200px !important;
    }
}
