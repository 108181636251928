$primary: #003E7E;
$gray-200: #E1E4E7;
$gray-300: #DEDEDE;
$gray-800: #53565A;
$yellow: #F4AF0E;
$dark-blue: #00205B;
$theme-colors: (
  "primary": $primary,
  "danger": #ff4136,
  "dark-blue": $dark-blue,
);
$table-color: $gray-200;