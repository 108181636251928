.home-hero {
    height: 400px;
    background-size: cover;
}

.logo-floater {
    width: 80px;
    height: 110px;
    position: absolute;
    z-index: 10;
    top: 12px;
    left: 13.5%;
    right: 0;
    bottom: 0;
    @media screen and (max-width: 1950px){
        display: flex;
        left: 12.5%;
    }
    @media screen and (max-width: 1900px){
        display: flex;
        left: 11.5%;
    }
    @media screen and (max-width: 1850px){
        display: flex;
        left: 10.5%;
    }
    @media screen and (max-width: 1760px){
        display: flex;
        left: 9.5%;
    }
    @media screen and (max-width: 1710px){
        display: flex;
        left: 8.5%;
    } 
    @media screen and (max-width: 1670px){
        display: flex;
        left: 7%;
    }
    @media screen and (max-width: 1610px){
        display: flex;
        left: 5%;
    }
    @media screen and (max-width: 1550px){
        display: flex;
        left: 2.5%;
    }
    @media screen and (max-width: 1460px){
        display: flex;
        left: 1%;
    }
    @media screen and (max-width: 1410px){
        display: flex;
        left: 0;
    }
    @media screen and (max-width: 1385px){
        display: none;
    }
}

.home-boxes {
justify-content: center;
    & > div {
        color: #fff;
        padding-bottom: 0.75rem;
        margin-right: 20px !important;
        margin-bottom: 20px;
    }
    & > div:nth-child(odd) {
        background-color: $primary;
    }
    & > div:nth-child(even) {
        background-color: lighten($primary, 5%);
    }
    h2 {
        color:white;
        padding: 1.5rem 2rem 0 2rem;
    }
    a{
        color:white;
        border-bottom: 1px dotted;
    }
    a:hover {
        text-decoration: none;
        border-bottom: none;
    }
    p {
        color:white;
        padding: .25rem 2rem;
        flex-grow: 1;
    }
    .col-6 {
        display: flex;
        flex-direction: column;
        flex: 0 0 23%;
        max-width: 23%;
    }
    .button {
        text-align: center;
        padding: .5rem 1.5rem;
        background-color: #FFFFFF;
        border: none;
        color: $primary;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
        border-radius: 3px;
        align-self: center;
        width: 80%;
    }
}
#footer-logotype {
    margin-left: auto;
    #footer-menu {
        margin-left: 115;
        nav {
            margin-left: 15.5px;
            @media screen and (max-width: 570px){
                margin-left: -43px;
            }
        }
    }
    @media screen and (max-width: 1350px){
        margin-left: auto;
        .logo-floater {
            display: flex;
        }
    }
    @media screen and (max-width: 800px){
        margin-left: auto;
        .logo-floater {
            display: none;
        }
    }
}

#google_translate_element {
    .skiptranslate {
        color: white;
    }
    .goog-logo-link {
        color: white;
    }
}

.popup {
    position: fixed;
    z-index: 2;
    top: 10%;
    @include media-breakpoint-up(md) {
        top: 25%;
    }
    color: white;
    display: flex;
    background: lighten($primary, 5%);
    box-shadow: 2px -1px 6px 4px rgba(0, 0, 0, .4);
    .w-auto {
        min-width: 300px;
    }
    .h-auto {
        min-height: 400px;
    }
    .button {
        margin: 1.25rem 2rem;
        text-align: center;
        padding: .5rem 1.5rem;
        background-color: #FFFFFF;
        border: none;
        color: $primary;
        text-decoration: none;
        display: inline-block;
        font-size: 1.2em;
        border-radius: 3px;
        align-self: center;
        width: 80%;
        max-width: 330px;
    }
    #mdiv {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 25px;
        height: 25px;
        background-color: white;
        border-radius: 50%;
      }
    #mdiv:hover {
        cursor: pointer;
    }
    p{
        color: white;
        padding-left: 20px;
        padding-right: 20px;
    }
    h2{
        color: white;
    }
    .mdiv {
    height: 15px;
    width: 2px;
    margin-left: 12px;
    margin-top: 6px;
    background-color: $dark-blue;
    transform: rotate(45deg);
    Z-index: 1;
    }

    .md {
    height: 15px;
    width: 2px;
    background-color: $dark-blue;
    transform: rotate(90deg);
    Z-index: 2;
    }
}